import './App.css';
import logo from './assets/logo.webp';
import "@fontsource/rubik";

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex justify-center w-screen">
        <header className="w-screen p-6">
          <img src={logo} className="app-logo" alt="logo" />
        </header>
      </div>
      <div className="app-body w-screen p-6 my-6 flex justify-center flex-grow">
        <div className="grid place-items-center place-content-center">
          <div className="text-6xl leading-snug sm:text-8xl sm:leading-tight font-semibold">
            Mobile App<br/>Consulting:<br/>Android. iOS.<br/>Flutter.
          </div>
        </div>
      </div>
      <div>
        <footer className="app-body flex justify-center w-screen p-6">
            © 2024, All Rights Reserved. Nofman Pty. Ltd.
        </footer>
      </div>
    </div>
  );
}

export default App;
